import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ResetPassword from "../views/resetPassword.vue";
import users from "@/views/admin/users.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  if (localStorage.getItem("token")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/signin");
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },
  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/about.vue"),
  }, 
  {
    path: "/blog-details",
    name: "Blog Details",
    component: () => import("../views/blog-details.vue"),
  }, 
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/blog.vue"),
  }, 
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/admin/categories.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/checkout.vue"),
    beforeEnter: authGuard,
  }, 
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/contact.vue"),
  }, 
  {
    path: "/index",
    name: "Home",
    component: () => import("../views/index.vue"),
  }, 
  {
    path: "/shop-details",
    name: "Shop Details",
    component: () => import("../views/shop-details.vue"),
  }, 
  {
    path: "/shop",
    name: "Shop",
    component: () => import("../views/shop.vue"),
  }, 
  {
    path: "/shopping-cart",
    name: "Shopping Cart",
    component: () => import("../views/shopping-cart.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: () => import("../views/signin.vue"),
  },  
  {
    path: "/profile",
    name: "User Profile",
    component: () => import("../views/Profile.vue"),
  }, 
    
  {
    path: "/client-profile",
    name: "User Profile",
    component: () => import("../views/admin/profile.vue"),
    beforeEnter: authGuard,
  }, 
    
  {
    path: "/wish-list",
    name: "Wish List",
    component: () => import("../views/wishlist.vue"),
    beforeEnter: authGuard,
  }, 
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { title: "Dashboard" },
  },

  {
    path: "/users",
    name: "users",
    component: () => import("../views/admin/users.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
