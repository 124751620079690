<template>
  <router-view/>
</template>

<script>
export default {
  // Add any necessary component options here
}
</script>

<style>
/* Add your component-specific styles here */
</style>
