<template>
    <!-- Add User Modal -->
    <div class="modal fade" id="verifyModalContent" tabindex="-1" role="dialog" aria-labelledby="verifyModalContent"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="verifyModalContent_title">Add Admin</h5>
                    <button class="btn btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form @submit="addUser" :validation-schema="schema" class="user">
                    <div class="modal-body">
                        <div class="row row-xs">
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="first_name">First Name:</label>
                                <Field name="first_name" class="form-control" id="first_name" type="text"
                                    placeholder="first name" />
                                <ErrorMessage name="first_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="second_name">Second Name:</label>
                                <Field name="second_name" class="form-control" id="second_name" type="text"
                                    placeholder="second name" />
                                <ErrorMessage name="second_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="last_name">Last Name:</label>
                                <Field name="last_name" class="form-control" id="last_name" type="text"
                                    placeholder="last name" />
                                <ErrorMessage name="last_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="email">Email:</label>
                                <Field name="email" class="form-control" id="email" type="text" placeholder="email" />
                                <ErrorMessage name="email" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="phone_number">Phone Number:</label>
                                <Field name="phone_number" class="form-control" id="phone_number" type="text"
                                    placeholder="phone number" />
                                <ErrorMessage name="phone_number" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="postal_code">Postal Code:</label>
                                <Field name="postal_code" class="form-control" id="postal_code" type="text"
                                    placeholder="postal code" />
                                <ErrorMessage name="postal_code" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="zip_code">Zip Code:</label>
                                <Field name="zip_code" class="form-control" id="zip_code" type="text"
                                    placeholder="zip code" />
                                <ErrorMessage name="zip_code" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="street">Street:</label>
                                <Field name="street" class="form-control" id="street" type="text"
                                    placeholder="street" />
                                <ErrorMessage name="street" class="text-danger p-3" />
                            </div>
                            <!-- <div class="form-group col-md-6">
                                <label for="country" class="form-control-label">Country Name</label>
                                <Field name="country" class="form-control form-control-lg" v-model="country" as="select">
                                    <option value="">-- Country --</option>
                                    <option v-for="country in allcountries" :value="country._id" :key="country._id">
                                        {{ country.country_name }}
                                    </option>
                                </Field>
                                <ErrorMessage name="country" class="text-danger py-3 text-sm" />
                            </div> -->
                            <div class="form-group col-md-6">
                                <label for="gender" class="form-control-label">Gender</label>
                                <Field name="gender" class="form-control form-control-lg" v-model="gender" as="select">
                                    <option value="">-- Gender --</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                </Field>
                                <ErrorMessage name="gender" class="text-danger py-3 text-sm" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="password">Password:</label>
                                <Field name="password" class="form-control" id="password" type="password" />
                                <ErrorMessage name="password" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="confirmpassword">Confirm Password:</label>
                                <Field name="confirmpassword" class="form-control" id="confirmpassword"
                                    type="password" />
                                <ErrorMessage name="confirmpassword" class="text-danger p-3" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary" type="Submit">Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- Edit User Modal -->
    <div class="modal fade" id="editModalContent" tabindex="-1" role="dialog" aria-labelledby="editModalContent"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editModalContent_title">Edit Admin</h5>
                    <button class="btn btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form @submit="editUser" :validation-schema="schema" class="user">
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="col-form-label mb-0" for="first_name">First Name:</label>
                                <Field name="first_name" class="form-control" v-model="first_name" type="text" />
                                <ErrorMessage name="first_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="second_name">Second Name:</label>
                                <Field name="second_name" class="form-control" v-model="second_name" type="text" />
                                <ErrorMessage name="second_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="last_name">Last Name:</label>
                                <Field name="last_name" class="form-control" v-model="last_name" type="text" />
                                <ErrorMessage name="last_name" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="email">Email:</label>
                                <Field name="email" class="form-control" v-model="email" type="text" />
                                <ErrorMessage name="email" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="phone_number">Phone Number:</label>
                                <Field name="phone_number" class="form-control" v-model="phone_number" type="text" />
                                <ErrorMessage name="phone_number" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="postal_code">Postal Code:</label>
                                <Field name="postal_code" class="form-control" v-model="postal_code" type="text" />
                                <ErrorMessage name="postal_code" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="zip_code">Zip Code:</label>
                                <Field name="zip_code" class="form-control" v-model="zip_code" type="text" />
                                <ErrorMessage name="zip_code" class="text-danger p-3" />
                            </div>
                            <div class="form-group col-md-6">
                                <label class="col-form-label" for="street">Street:</label>
                                <Field name="street" class="form-control" v-model="street" type="text" />
                                <ErrorMessage name="street" class="text-danger p-3" />
                            </div>
                            <!-- <div class="form-group col-md-6">
                                <label for="country" class="form-control-label">Country Name</label>
                                <Field name="country" class="form-control form-control-lg" v-model="country" as="select">
                                    <option value="">-- Country --</option>
                                    <option v-for="country in allcountries" :value="country._id" :key="country._id">
                                        {{ country.country_name }}
                                    </option>
                                </Field>
                                <ErrorMessage name="country" class="text-danger py-3 text-sm" />
                            </div> -->
                            <div class="form-group col-md-6">
                                <label for="gender" class="form-control-label">Gender</label>
                                <Field name="gender" class="form-control form-control-lg" v-model="gender" as="select">
                                    <option value="">-- Gender --</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                </Field>
                                <ErrorMessage name="gender" class="text-danger py-3 text-sm" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary" type="submit">Save changes</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as yup from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            users: [],
            first_name: '',
            second_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            postal_code: '',
            zip_code: '',
            street: '',
            country: '',
            gender: '',
            allcountries: [],
            schema: yup.object({
                first_name: yup.string().required('First name is required'),
                second_name: yup.string().required('Second name is required'),
                last_name: yup.string().required('Last name is required'),
                email: yup.string().required('Email is required').email('Invalid email'),
                phone_number: yup.string().required('Phone number is required'),
                // postal_code: yup.string().required('Postal code is required'),
                // zip_code: yup.string().required('Zip code is required'),
                // street: yup.string().required('Street is required'),
                // country: yup.string().required('Country is required'),
                // gender: yup.string().required('Gender is required'),
                password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
                confirmpassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password')], 'Passwords must match'),
            }),
        };
    },
    async mounted() {
        // await this.getAllCountries();
        await this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            try {
                const response = await axios.get('https://api.rescueministryseries.org/users');
                this.users = response.data;
            } catch (error) {
                console.error(error);
            }
        },
        // async getAllCountries() {
        //     try {
        //         const response = await axios.get('https://api.rescueministryseries.org/countries');
        //         this.allcountries = response.data;
        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
        async addUser(values) {
            try {
                await axios.post('https://api.rescueministryseries.org/register', values);
                this.fetchUsers();
                this.$bvModal.hide('verifyModalContent');
            } catch (error) {
                console.error(error);
            }
        },
        async editUser(values) {
            try {
                const userId = this.$route.params.id;
                await axios.put(`https://api.rescueministryseries.org/users/${userId}`, values);
                this.fetchUsers();
                this.$bvModal.hide('editModalContent');
            } catch (error) {
                console.error(error);
            }
        },
        showEditModal(user) {
            this.first_name = user.first_name;
            this.second_name = user.second_name;
            this.last_name = user.last_name;
            this.email = user.email;
            this.phone_number = user.phone_number;
            this.postal_code = user.postal_code;
            this.zip_code = user.zip_code;
            this.street = user.street;
            // this.country = user.country;
            this.gender = user.gender;
            this.$bvModal.show('editModalContent');
        },
        clearForm() {
            this.first_name = '';
            this.second_name = '';
            this.last_name = '';
            this.email = '';
            this.phone_number = '';
            this.postal_code = '';
            this.zip_code = '';
            this.street = '';
            // this.country = '';
            this.gender = '';
        }
    }
};
</script>

<style scoped>
.text-danger {
    color: #e3342f;
}
</style>
