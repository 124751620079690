// store/index.js

import axios from "axios";

import { createStore } from "vuex";

const store = createStore({
  state: {
    user: null,
    cartItems: [],
    wishlistItems: [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    addToCart(state, item) {
      state.cartItems.push(item);
    },
    // setWishlistItems(state, items) {
    //   state.wishlistItems = items;
    // },
    // addToWishlist(state, item) {
    //   state.wishlistItems.push(item);
    // },
    // removeFromWishlist(state, itemId) {
    //   state.wishlistItems = state.wishlistItems.filter(item => item.id !== itemId);
    // },
  },
  actions: {
    // Add any actions if needed
    async addToCart({ commit }, item) {
      try {
        const response = await axios.post("/cart", item); // Example API endpoint
        commit("addToCart", response.data);
        // Optionally, show a success message or update UI
      } catch (error) {
        console.error("Error adding to cart:", error);
        // Handle error state or show error message
      }
    },
    // async fetchWishlistItems({ commit }) {
    //   try {
    //     const response = await axios.get('/wishlist'); // Replace with actual API endpoint
    //     commit('setWishlistItems', response.data);
    //   } catch (error) {
    //     console.error('Error fetching wishlist items:', error);
    //     // Handle error state or show error message
    //   }
    // },
    // async addToWishlist({ commit }, item) {
    //   try {
    //     const response = await axios.post('/wishlist', item); // Replace with actual API endpoint
    //     commit('addToWishlist', response.data);
    //     // Optionally, show a success message or update UI
    //   } catch (error) {
    //     console.error('Error adding to wishlist:', error);
    //     // Handle error state or show error message
    //   }
    // },
    // async removeFromWishlist({ commit }, itemId) {
    //   try {
    //     await axios.delete(`/wishlist/${itemId}`); // Replace with actual API endpoint
    //     commit('removeFromWishlist', itemId);
    //     // Optionally, show a success message or update UI
    //   } catch (error) {
    //     console.error('Error removing from wishlist:', error);
    //     // Handle error state or show error message
    //   }
    // },
  },
  getters: {
    // Add any getters if needed
  },
});

export default store;
